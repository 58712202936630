import axios from 'axios';
import * as types from './AdminLayout.types';
import * as selectors from './AdminLayout.selectors';
import { adminValidateAuthInResponse } from './../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

export const setDefaultChart = (rowNumber, value) => (dispatch, getState) => {
    dispatch({ type: types.SET_DEFAULT_CHART, payload: null });
    axios.post(apiUrl + '/record/set-default-chart', { rowNumber, value },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.SET_DEFAULT_CHART_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(adminValidateAuthInResponse(error, types.SET_DEFAULT_CHART_ERROR));
        });
}

export const setShowSidebarMenu = () => (dispatch, getState) => {
    const showSidebarMenu = selectors.getShowSidebarMenu(getState());

    if (!showSidebarMenu) {
        document.body.classList.add('no-scroll');
    } else {
        document.body.classList.remove('no-scroll');
    }

    dispatch({
        type: types.SET_SHOW_SIDEBAR_MENU,
        payload: !showSidebarMenu,
    })
}

export const setDataType = (value) => (dispatch) => {
    dispatch({
        type: types.SET_DATA_TYPE,
        payload: value,
    });
}
