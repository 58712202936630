import React from 'react';
import { Link } from 'react-router-dom';

const Header = (props) => {
    const handleLogoClick = () => {
        props.history.push('/')
    };

    const handleSetDataType = (event) => {
        props.setDataType(event.target.value);
        if (props.setDataTypeCallback) {
            props.setDataTypeCallback();
        }
    };

    let sidebarMenuClass = 'sidebar-menu';
    let overlayClass = 'overlay';
    if (props.showSidebarMenu) {
        sidebarMenuClass = 'sidebar-menu show-menu';
        overlayClass = 'overlay show-menu';
    }

    let usersMenu = null;
    if (parseInt(props.user.type) === 1) {  // Super Admin
        usersMenu = <li><Link to="/users" onClick={props.setShowSidebarMenu}>Users</Link></li>;
    }

    return (
        <div className="header-container">
            <div className={sidebarMenuClass}>
                <div className="sidebar-content">
                    <div className="close-icon">
                        <i className="fa-solid fa-xmark" onClick={props.setShowSidebarMenu}></i>
                    </div>
                    <div className="menu-items">
                        <ul>
                            <li><Link to="/" onClick={props.setShowSidebarMenu}>Dashboard</Link></li>
                            <li><Link to="/transactions" onClick={props.setShowSidebarMenu}>Transactions</Link></li>
                            {usersMenu}
                            <li onClick={props.logoutHandler}>Log Out</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className={overlayClass}></div>
            <div className="menu">
                <div className="content">
                    <div className="logo" onClick={handleLogoClick}>
                        <img src="https://sc-eps.s3.amazonaws.com/logo.png" alt="EPS" />
                    </div>
                    <div className="menu-items">
                        <div className="data-type">
                            Data Type: 
                            <select value={props.dataType} onChange={handleSetDataType}>
                                <option value="1">All Transactions</option>
                                <option value="2">Daily EPS</option>
                            </select>
                        </div>
                        <div className="welcome">
                            <i className="fa-solid fa-user"></i> Welcome back <b>{props.user.first_name}</b>
                        </div>
                        <div className="logout-link" onClick={props.logoutHandler}><i className="fas fa-sign-out-alt" /> Log Out</div>
                    </div>
                </div>
            </div>
            <div className="mobile-menu">
                <div className="menu-icon" onClick={props.setShowSidebarMenu}>
                    <i className="fa-solid fa-bars"></i>
                </div>
                <div className="logo" onClick={handleLogoClick}>
                    <img src="https://sc-eps.s3.amazonaws.com/logo.png" alt="EPS" />
                </div>
                <div className="cart-icon"></div>
            </div>
        </div>
    );
}

export default Header;
