import axios from 'axios';
import * as types from './Records.types';
import * as selectors from './Records.selectors';
import * as layoutSelectors from './../AdminLayout/AdminLayout.selectors';
import * as loginActions from './../Login/Login.actions';
import { adminValidateAuthInResponse, showNotification } from './../../utils';
import * as dialog from './../common/dialog';
import fileDownload from 'js-file-download';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchElements = () => (dispatch, getState) => {
    const pagination = selectors.getPagination(getState());
    const sort = selectors.getSort(getState());
    const search = selectors.getSearch(getState());
    const searchType = selectors.getSearchType(getState());
    const startDate = selectors.getStartDate(getState());
    const endDate = selectors.getEndDate(getState());
    const year = selectors.getYear(getState());
    const month = selectors.getMonth(getState());
    const filters = selectors.getFilters(getState());
    const dataType = layoutSelectors.getDataType(getState());

    let params = [];
    params.push('active-page=' + pagination.activePage);
    params.push('elements-per-page=' + pagination.elementsPerPage);
    params.push('sort-column=' + sort.column);
    params.push('sort-type=' + sort.type);
    params.push('search=' + search);
    params.push('search-type=' + searchType);
    params.push('start-date=' + startDate);
    params.push('end-date=' + endDate);
    params.push('year=' + year);
    params.push('month=' + month);
    params.push('data-type=' + dataType);

    let filterElements = [];
    for (const key in filters) {
        filterElements.push(key + '=' + filters[key]);
    }

    if (filterElements.length > 0) {
        params.push('filter=' + filterElements.join(','))
    } else {
        params.push('filter=-')
    }

    dispatch(setOldFilters(filters));

    dispatch({ type: types.FETCH_ELEMENTS, payload: null });
    axios.get(apiUrl + '/record/admin-index?' + params.join('&'),
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_ELEMENTS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(adminValidateAuthInResponse(error, types.FETCH_ELEMENTS_ERROR));
        });
}

export const fetchElement = (id) => (dispatch, getState) => {
    const formType = selectors.getFormType(getState());

    dispatch({ type: types.FETCH_ELEMENT, payload: null });
    axios.get(apiUrl + '/records/' + id + '?form-type=' + formType,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_ELEMENT_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(adminValidateAuthInResponse(error, types.FETCH_ELEMENT_ERROR));
        });
}

export const saveElement = () => (dispatch, getState) => {
    const item = selectors.getItem(getState());
    const history = selectors.getHistory(getState());
    const formType = selectors.getFormType(getState());

    let endpoint = apiUrl + '/records';
    let method = 'post';
    if (item.id && item.id != null) {
        endpoint = apiUrl + '/records/' + item.id;
        method = 'put';
    }

    dispatch({ type: types.SAVE_ELEMENT, payload: null });
    axios({
        method: method,
        url: endpoint,
        data: { item, formType },
        headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
    }).then((response) => {
        dispatch({ type: types.SAVE_ELEMENT_SUCCESS, payload: response.data });
        showNotification('Item Saved', 'The item has been saved successfully', 'success');
        history.push('/transactions')
    }).catch((error) => {
        dispatch({ type: types.SAVE_ELEMENT_ERROR, payload: null });
        if (error.response && error.response.status === 401) {
            showNotification('Unauthorized', 'You are not authorized to see this page', 'warning');
        } else if (error.response && error.response.status === 409) {
            showNotification('Invalid data', 'The Email or Username already exist', 'info');
        } else if (error.response && error.response.status === 403) {
            dispatch(loginActions.logout());
        } else {
            showNotification('Error', 'An error has occurred!', 'danger');
        }
    });
}

export const deleteItem = () => (dispatch, getState) => {
    const idModal = selectors.getIdModal(getState());
    const deleteType = selectors.getDeleteType(getState());
    const dataType = layoutSelectors.getDataType(getState());

    dispatch({ type: types.DELETE_ITEM, payload: null });
    axios.delete(apiUrl + '/records/' + idModal + '?delete-type=' + deleteType + '&data-type=' + dataType,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.DELETE_ITEM_SUCCESS, payload: response.data });
            dispatch(dialog.actions.hideDialog('delete-modal'));
            showNotification('Item(s) Deleted', 'Item(s) deleted successfully', 'success');
            dispatch(fetchElements());
        })
        .catch((error) => {
            dispatch(adminValidateAuthInResponse(error, types.DELETE_ITEM_ERROR));
        });
}

export const uploadFile = () => (dispatch, getState) => {
    const file = selectors.getFile(getState());
    const fileType = selectors.getFileType(getState());

    if (file == null) {
        return showNotification('Complete Information', 'Select a File', 'info');
    }

    var formData = new FormData();
    formData.append('file', file);
    formData.append('file-type', fileType);

    dispatch({ type: types.UPLOAD_FILE, payload: null });
    axios.post(apiUrl + '/record/upload-excel', formData,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.UPLOAD_FILE_SUCCESS, payload: response.data });
            dispatch(dialog.actions.hideDialog('upload-file-modal'));
            dispatch(fetchElements());
        })
        .catch((error) => {
            dispatch(adminValidateAuthInResponse(error, types.UPLOAD_FILE_ERROR));
        });
}

export const exportExcel = () => (dispatch, getState) => {
    const year = selectors.getYear(getState());
    const month = selectors.getMonth(getState());
    const filters = selectors.getFilters(getState());
    const dataType = layoutSelectors.getDataType(getState());

    let params = [];
    params.push('year=' + year);
    params.push('month=' + month);
    params.push('data-type=' + dataType);

    let filterElements = [];
    for (const key in filters) {
        filterElements.push(key + '=' + filters[key]);
    }

    if (filterElements.length > 0) {
        params.push('filter=' + filterElements.join(','))
    } else {
        params.push('filter=-')
    }

    dispatch({ type: types.EXPORT_EXCEL, payload: null });
    axios.get(apiUrl + '/record/export-excel?' + params.join('&'),
        {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
            responseType: 'blob',
        })
        .then((response) => {
            dispatch({ type: types.EXPORT_EXCEL_SUCCESS, payload: response.data });

            if (parseInt(dataType) === 1) {
                fileDownload(response.data, 'All Transactions ' + year + ' - ' + month + '.xlsx');
            } else if (parseInt(dataType) === 2) {
                fileDownload(response.data, 'Daily EPS ' + year + ' - ' + month + '.xlsx');
            }
        })
        .catch((error) => {
            dispatch(adminValidateAuthInResponse(error, types.EXPORT_EXCEL_ERROR));
        });
}

export const setActionType = (value) => (dispatch) => {
    dispatch({
        type: types.SET_ACTION_TYPE,
        payload: value
    });
}

export const setActivePage = (value) => (dispatch, getState) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_ACTIVE_PAGE,
            payload: value,
        });
        resolve();
    }).then(() => {
        dispatch(fetchElements());
    });
}

export const setSort = (column, type) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_SORT,
            payload: { column, type }
        });
        resolve();
    }).then(() => {
        dispatch(fetchElements());
    });
}

export const setSearch = (value) => (dispatch) => {
    dispatch({
        type: types.SET_SEARCH,
        payload: value,
    });
}

export const setSearchType = (value) => (dispatch) => {
    dispatch({
        type: types.SET_SEARCH_TYPE,
        payload: value,
    });
}

export const setField = (field, value) => (dispatch) => {
    dispatch({
        type: types.SET_FIELD,
        payload: { field, value },
    });
}

export const setFile = (file) => (dispatch) => {
    dispatch({
        type: types.SET_FILE,
        payload: file,
    });
}

export const setHistory = (value) => (dispatch) => {
    dispatch({
        type: types.SET_HISTORY,
        payload: value,
    });
}

export const setIdModal = (id) => (dispatch) => {
    dispatch({
        type: types.SET_ID_MODAL,
        payload: id,
    });
}

export const setStartDate = (value) => (dispatch) => {
    dispatch({
        type: types.SET_START_DATE,
        payload: value,
    });
}

export const setEndDate = (value) => (dispatch) => {
    dispatch({
        type: types.SET_END_DATE,
        payload: value,
    });
}

export const setYear = (value) => (dispatch) => {
    dispatch({
        type: types.SET_YEAR,
        payload: value,
    });
}

export const setMonth = (value) => (dispatch) => {
    dispatch({
        type: types.SET_MONTH,
        payload: value,
    });
}

export const setDeleteType = (value) => (dispatch) => {
    dispatch({
        type: types.SET_DELETE_TYPE,
        payload: value,
    });
}

export const setFilter = (name, value) => (dispatch) => {
    dispatch({
        type: types.SET_FILTER,
        payload: { name, value },
    });
}

export const setOldFilters = (filters) => (dispatch) => {
    dispatch({
        type: types.SET_OLD_FILTERS,
        payload: filters,
    });
}

export const setFileType = (value) => (dispatch) => {
    dispatch({
        type: types.SET_FILE_TYPE,
        payload: value,
    });
}

export const setFormType = (value) => (dispatch) => {
    dispatch({
        type: types.SET_FORM_TYPE,
        payload: value,
    });
}

export const clearFilters = (value) => (dispatch) => {
    dispatch({
        type: types.CLEAR_FILTERS,
        payload: value,
    });
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null,
    })
}