import axios from 'axios';
import * as types from './AdminUsers.types';
import * as selectors from './AdminUsers.selectors';
import * as loginActions from './../Login/Login.actions';
import { adminValidateAuthInResponse, showNotification, empty } from './../../utils';
import * as dialog from './../common/dialog';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchElements = () => (dispatch, getState) => {
    const pagination = selectors.getPagination(getState());
    const sort = selectors.getSort(getState());
    const search = selectors.getSearch(getState());
    const searchType = selectors.getSearchType(getState());

    let params = [];
    params.push('active-page=' + pagination.activePage);
    params.push('elements-per-page=' + pagination.elementsPerPage);
    params.push('sort-column=' + sort.column);
    params.push('sort-type=' + sort.type);
    params.push('search=' + search);
    params.push('search-type=' + searchType);

    dispatch({ type: types.FETCH_ELEMENTS, payload: null });
    axios.get(apiUrl + '/user/admin-index?' + params.join('&'),
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_ELEMENTS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(adminValidateAuthInResponse(error, types.FETCH_ELEMENTS_ERROR));
        });
}

export const fetchElement = (id) => (dispatch, getState) => {
    dispatch({ type: types.FETCH_ELEMENT, payload: null });
    axios.get(apiUrl + '/users/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_ELEMENT_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(adminValidateAuthInResponse(error, types.FETCH_ELEMENT_ERROR));
        });
}

export const saveElement = () => (dispatch, getState) => {
    const item = selectors.getItem(getState());
    const history = selectors.getHistory(getState());

    if (!validateSaveFields(item)) {
        return;
    }

    let endpoint = apiUrl + '/users';
    let method = 'post';
    if (item.id && item.id != null) {
        endpoint = apiUrl + '/users/' + item.id;
        method = 'put';
    }

    dispatch({ type: types.SAVE_ELEMENT, payload: null });
    axios({
        method: method,
        url: endpoint,
        data: item,
        headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
    }).then((response) => {
        dispatch({ type: types.SAVE_ELEMENT_SUCCESS, payload: response.data });
        showNotification('Item Saved', 'The item has been saved successfully', 'success');
        history.push('/users')
    }).catch((error) => {
        dispatch({ type: types.SAVE_ELEMENT_ERROR, payload: null });
        if (error.response && error.response.status === 401) {
            showNotification('Unauthorized', 'You are not authorized to see this page', 'warning');
        } else if (error.response && error.response.status === 409) {
            showNotification('Invalid data', 'The Email or Username already exist', 'info');
        } else if (error.response && error.response.status === 403) {
            dispatch(loginActions.logout());
        } else {
            showNotification('Error', 'An error has occurred!', 'danger');
        }
    });
}

export const deleteItem = () => (dispatch, getState) => {
    const idModal = selectors.getIdModal(getState());

    dispatch({ type: types.DELETE_ITEM, payload: null });
    axios.delete(apiUrl + '/users/' + idModal,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.DELETE_ITEM_SUCCESS, payload: response.data });
            dispatch(dialog.actions.hideDialog('delete-modal'));
            showNotification('Item Deleted', 'The item has been deleted successfully', 'success');
            dispatch(fetchElements());
        })
        .catch((error) => {
            dispatch(adminValidateAuthInResponse(error, types.DELETE_ITEM_ERROR));
        });
}

export const validateSaveFields = (fields) => {
    if (empty(fields.first_name)) {
        showNotification('Complete Information', 'Enter the First Name', 'info');
        return false;
    }
    if (empty(fields.last_name)) {
        showNotification('Complete Information', 'Enter the Last Name', 'info');
        return false;
    }
    if (empty(fields.email)) {
        showNotification('Complete Information', 'Enter the Email', 'info');
        return false;
    }

    if (!fields.id) {
        if (empty(fields.new_password)) {
            showNotification('Complete Information', 'Enter the New Password', 'info');
            return false;
        }
        if (empty(fields.confirm_password)) {
            showNotification('Complete Information', 'Enter the Confirm New Password', 'info');
            return false;
        }
    }

    if (!empty(fields.new_password) || !empty(fields.confirm_password)) {
        if (empty(fields.new_password)) {
            showNotification('Complete Information', 'Enter the New Password', 'info');
            return false;
        }
        if (empty(fields.confirm_password)) {
            showNotification('Complete Information', 'Enter the Confirm New Password', 'info');
            return false;
        }
        if (fields.new_password !== fields.confirm_password) {
            showNotification('Complete Information', 'The New Password and Confirm New Password are different', 'info');
            return false;
        }
    }

    return true;
}

export const setActionType = (value) => (dispatch) => {
    dispatch({
        type: types.SET_ACTION_TYPE,
        payload: value
    });
}

export const setActivePage = (value) => (dispatch, getState) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_ACTIVE_PAGE,
            payload: value,
        });
        resolve();
    }).then(() => {
        dispatch(fetchElements());
    });
}

export const setSort = (column, type) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_SORT,
            payload: { column, type }
        });
        resolve();
    }).then(() => {
        dispatch(fetchElements());
    });
}

export const setSearch = (value) => (dispatch) => {
    dispatch({
        type: types.SET_SEARCH,
        payload: value,
    });
}

export const setSearchType = (value) => (dispatch) => {
    dispatch({
        type: types.SET_SEARCH_TYPE,
        payload: value,
    });
}

export const setField = (field, value) => (dispatch) => {
    dispatch({
        type: types.SET_FIELD,
        payload: { field, value },
    });
}

export const setHistory = (value) => (dispatch) => {
    dispatch({
        type: types.SET_HISTORY,
        payload: value,
    });
}

export const setIdModal = (id) => (dispatch) => {
    dispatch({
        type: types.SET_ID_MODAL,
        payload: id
    });
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null,
    })
}