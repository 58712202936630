import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar = (props) => {
    const path = props.history.location.pathname;

    const handleClick = (event) => {
        event.preventDefault();
        props.showTermOfUseModal();
    };

    let usersMenu = null;
    if (parseInt(props.user.type) === 1) {  // Super Admin
        usersMenu = (
            <div className={path.includes('/users') ? 'sidebar-item active' : 'sidebar-item'}>
                <Link to="/users">
                    <i className="fa-solid fa-users"></i> Users
                </Link>
            </div>
        );
    }

    return (
        <div className="sidebar-container">
            <div className={path === '/' ? 'sidebar-item active' : 'sidebar-item'}>
                <Link to="/">
                    <i className="fa-solid fa-chart-pie"></i> Dashboard
                </Link>
            </div>
            <div className={path.includes('/transactions') ? 'sidebar-item active' : 'sidebar-item'}>
                <Link to="/transactions">
                    <i className="fa-solid fa-table-list"></i> Transactions
                </Link>
            </div>
            {usersMenu}
            <div className="term-of-use">
                <a href="/" onClick={handleClick}>Term of Use</a>
            </div>
        </div>
    );
}

export default Sidebar;
