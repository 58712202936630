import React, { Fragment } from 'react';
import EpsLineChart from './EpsLineChart';
import EpsAreaChart from './EpsAreaChart';
import EpsPieChart from './EpsPieChart';
import EpsBarChart from './EpsBarChart';

const Chart = (props) => {
    if (props.fetchingData || props.data.length === 0) {
        return (
            <div className="loading-data">
                <i className="fas fa-circle-notch fa-spin" /> Loading data...
            </div>
        );
    }

    const handleSetChartType = (chartName, event) => {
        props.setChartType(chartName, event.target.value);
    };

    const renderChart = (name, chartName, numberFormat) => {
        if (props.data[chartName].chartType === 'line') {
            return (
                <EpsLineChart
                    chartTitle={name}
                    data={props.data[chartName].data}
                    total={props.data[chartName].total}
                    numberFormat={numberFormat}
                    yAxisWidth={80}
                    chartType={props.data[chartName].chartType}
                    setChartType={handleSetChartType.bind(this, chartName)}
                    lines={[
                        { name: name, dataKey: 'value', color: props.data[chartName].color },
                    ]}
                />
            );
        } else if (props.data[chartName].chartType === 'area') {
            return (
                <EpsAreaChart
                    chartTitle={name}
                    data={props.data[chartName].data}
                    total={props.data[chartName].total}
                    numberFormat={numberFormat}
                    yAxisWidth={80}
                    chartType={props.data[chartName].chartType}
                    setChartType={handleSetChartType.bind(this, chartName)}
                    areas={[
                        { name: name, dataKey: 'value', color: props.data[chartName].color },
                    ]}
                />
            );
        } else if (props.data[chartName].chartType === 'bar') {
            return (
                <EpsBarChart
                    chartTitle={name}
                    data={props.data[chartName].data}
                    total={props.data[chartName].total}
                    numberFormat={numberFormat}
                    yAxisWidth={80}
                    chartType={props.data[chartName].chartType}
                    setChartType={handleSetChartType.bind(this, chartName)}
                    bars={[
                        { name: name, dataKey: 'value', color: props.data[chartName].color },
                    ]}
                />
            );
        }

        return null;
    };

    const renderAllTransactionsCharts = () => {
        if (parseInt(props.dataType) !== 1) {
            return null;
        }

        return (
            <Fragment>
                {renderChart('Total Transactions', 'transactionsChart', '0,0')}
                {renderChart('Amount', 'amountChart', '$0,0.00')}
                <EpsPieChart
                    chartTitle="Monthly Transactions by Account #"
                    data={props.data.accountChart ? props.data.accountChart.data : []}
                    setChartType={handleSetChartType}
                    numberFormat="0.00%"
                    colors={['#7e22ce', '#2563eb', '#be185d', '#16a34a', '#ea580c', '#eab308', '#475569', '#0d9488']}
                />
                <EpsPieChart
                    chartTitle="Monthly Transactions by CRID"
                    data={props.data.cridChart ? props.data.cridChart.data : []}
                    setChartType={handleSetChartType}
                    numberFormat="0.00%"
                    colors={['#2563eb', '#7e22ce', '#be185d', '#16a34a', '#ea580c', '#eab308', '#475569', '#0d9488']}
                />
                {renderChart('Pieces', 'piecesChart', '0,0')}
                <EpsPieChart
                    chartTitle="Monthly Transactions by Permit / Pub"
                    data={props.data.permitsChart ? props.data.permitsChart.data : []}
                    setChartType={handleSetChartType}
                    numberFormat="0.00%"
                    colors={['#7e22ce', '#2563eb', '#be185d', '#16a34a', '#ea580c', '#eab308', '#475569', '#0d9488']}
                />
                <EpsPieChart
                    chartTitle="Monthly Transactions by Type"
                    data={props.data.transactionTypesChart ? props.data.transactionTypesChart.data : []}
                    setChartType={handleSetChartType}
                    numberFormat="0.00%"
                    colors={['#2563eb', '#7e22ce', '#be185d', '#16a34a', '#ea580c', '#eab308', '#475569', '#0d9488']}
                />
                {renderChart('Containers', 'containersChart', '0,0')}
            </Fragment>
        );
    };

    const renderDailyEpsCharts = () => {
        if (parseInt(props.dataType) !== 2) {
            return null;;
        }

        return (
            <Fragment>
                {renderChart('Total Transactions', 'transactionsChart', '0,0')}
                {renderChart('Amount', 'amountChart', '$0,0.00')}
                <EpsPieChart
                    chartTitle="Monthly Transactions by Permit"
                    data={props.data.permitsChart ? props.data.permitsChart.data : []}
                    setChartType={handleSetChartType}
                    numberFormat="0.00%"
                    colors={['#7e22ce', '#2563eb', '#be185d', '#16a34a', '#ea580c', '#eab308', '#475569', '#0d9488']}
                />
                <EpsPieChart
                    chartTitle="Monthly Transactions by Type"
                    data={props.data.transactionTypesChart ? props.data.transactionTypesChart.data : []}
                    setChartType={handleSetChartType}
                    numberFormat="0.00%"
                    colors={['#2563eb', '#7e22ce', '#be185d', '#16a34a', '#ea580c', '#eab308', '#475569', '#0d9488']}
                />
            </Fragment>
        );
    };

    return (
        <div className="charts-container">
            {renderAllTransactionsCharts()}
            {renderDailyEpsCharts()}
        </div>
    );
}

export default Chart;
