import React from 'react';
import { Dialog } from './../../common/dialog';
import { useDropzone } from 'react-dropzone';

const UploadFileModal = React.memo((props) => {
    const {
        // acceptedFiles,
        // fileRejections,
        getRootProps,
        getInputProps
    } = useDropzone({
        multiple: false,
        accept: '.xls,.xlsx',
        onDrop: (files) => {
            props.setFile(files[0]);
        }
    });

    const hideHandler = () => {
        props.hideUploadFileModal();
    };

    const handleSetFileType = (event) => {
        props.setFileType(event.target.value);
    };

    const renderSelectedFile = () => {
        if (props.file == null) {
            return null;
        }

        return <div className="selected-file-container"><b>Selected File:</b> {props.file.name}</div>;
    };

    let label = 'Upload';
    if (props.uploadingFile) {
        label = <i className="fas fa-circle-notch fa-spin" />;
    }

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideHandler}>Cancel</button>,
        <button key="upload-button" className="btn btn-light btn-sm action-button" onClick={props.uploadFile} disabled={props.uploadingFile}>{label}</button>
    ];
    return (
        <Dialog
            name="upload-file-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="upload-file-dialog"
        >
            <div className="upload-file-modal-container">
                <h1>Upload File</h1>
                <div className="type-field">
                    File Type:
                    <select value={props.fileType} onChange={handleSetFileType}>
                        <option value="1">All Transactions</option>
                        <option value="2">Daily EPS</option>
                    </select>
                </div>
                <div className="file-field">
                    File:
                    <div {...getRootProps({ className: 'dropzone-container' })}>
                        <input {...getInputProps()} />
                        <button>Browse</button>
                    </div>
                    {renderSelectedFile()}
                </div>
            </div>
        </Dialog >
    );
});

export default UploadFileModal;
