import { createSelector } from 'reselect';

export const getComponentState = state => state.dashboard;

export const getData = createSelector(
    getComponentState,
    (state) => state.data
);
export const getFetchingData = createSelector(
    getComponentState,
    (state) => state.fetchingData
);
export const getYear = createSelector(
    getComponentState,
    (state) => state.year
);
export const getMonth = createSelector(
    getComponentState,
    (state) => state.month
);
export const getRow = createSelector(
    getComponentState,
    (state) => state.row
);
export const getHistory = createSelector(
    getComponentState,
    (state) => state.history
);