import { createSelector } from 'reselect';

export const getComponentState = state => state.adminLayout;

export const getShowSidebarMenu = createSelector(
    getComponentState,
    (state) => state.showSidebarMenu
);
export const getDataType = createSelector(
    getComponentState,
    (state) => state.dataType
);