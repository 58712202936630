import React from 'react';
import numeral from 'numeral';
import { empty } from './../../../utils';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine, Label } from 'recharts';

const EpsBarChart = (props) => {
    const calculateMinPoint = (dataMin) => {
        return dataMin;
    };

    const calculateMaxPoint = (dataMax) => {
        if (!empty(props.threshold)) {
            if (props.threshold > dataMax) {
                return props.threshold * 1.05;
            } else {
                return dataMax;
            }
        }

        return dataMax;
    };

    const formatYAxis = (format, element) => {
        return numeral(element).format(format);
    };

    const tooltipFormatter = (format, element) => {
        return numeral(element).format(format);
    };

    let numberFormat = '0,0';
    if (props.numberFormat && props.numberFormat != null) {
        numberFormat = props.numberFormat;
    }

    let yAxisWidth = 80;
    if (props.yAxisWidth && props.yAxisWidth != null) {
        yAxisWidth = props.yAxisWidth;
    }

    let chartContainer = <div className="no-data">No data.</div>;
    if (props.data && props.data.length > 0) {
        let thresholdLine = null;
        if (!empty(props.threshold)) {
            thresholdLine = (
                <ReferenceLine y={props.threshold} stroke="#dc2626" strokeDasharray="5 5">
                    <Label value={'Threshold (' + numeral(props.threshold).format('0.[0000]%') + ')'} position="insideBottom" />
                </ReferenceLine>
            );
        }

        let stackId = null;
        if (props.bars.length > 0) {
            stackId = 'a';
        }

        chartContainer = (
            <ResponsiveContainer>
                <BarChart {...props}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis
                        type="number"
                        domain={[calculateMinPoint, calculateMaxPoint]}
                        tickCount="10"
                        tickFormatter={formatYAxis.bind(this, numberFormat)}
                        width={yAxisWidth}
                    />
                    <Tooltip formatter={tooltipFormatter.bind(this, numberFormat)} />
                    <Legend />
                    {thresholdLine}
                    {props.bars.map((bars) => {
                        return <Bar type="monotone" name={bars.name} dataKey={bars.dataKey} stackId={stackId} fill={bars.color} />;
                    })}
                </BarChart>
            </ResponsiveContainer>
        );
    }

    let totalRow = null;
    if (props.total) {
        totalRow = (
            <div className="total">
                <h2>Monthly Total: <span className="value">{numeral(props.total).format(numberFormat)}</span></h2>
            </div>
        );
    }

    return (
        <div className="chart-container">
            <h2>
                <div className="chart-title">{props.chartTitle}</div>
                <div className="chart-type-field">
                    <span>Chart Type:</span>
                    <select value={props.chartType} onChange={props.setChartType}>
                        <option value="line">Line</option>
                        <option value="area">Area</option>
                        <option value="bar">Bar</option>
                    </select>
                </div>
            </h2>
            <div className="chart" style={{ width: '100%', height: 400 }}>
                {chartContainer}
            </div>
            {totalRow}
        </div>
    );
}

export default EpsBarChart;
