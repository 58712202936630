import React, { useCallback, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './Records.scss';
import Table from './Table';
import Form from './Form';
import DeleteModal from './DeleteModal';
import UploadFileModal from './UploadFileModal';
import AdminLayout from './../AdminLayout';
import * as actions from './Records.actions';
import * as selectors from './Records.selectors';
import * as layoutSelectors from './../AdminLayout/AdminLayout.selectors';
import * as loginSelectors from './../Login/Login.selectors';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';
import { Link } from 'react-router-dom';
import * as dialog from './../common/dialog';
import moment from 'moment';
import { Redirect } from 'react-router-dom';

const Records = (props) => {
    /* State to props */
    const pagination = useSelector(selectors.getPagination, stringifyEqualityCheck);
    const actionType = useSelector(selectors.getActionType);
    const items = useSelector(selectors.getItems, stringifyEqualityCheck);
    const item = useSelector(selectors.getItem, stringifyEqualityCheck);
    const sort = useSelector(selectors.getSort, stringifyEqualityCheck);
    const search = useSelector(selectors.getSearch);
    const searchType = useSelector(selectors.getSearchType);
    const fetchingElements = useSelector(selectors.getFetchingElements);
    const fetchingElement = useSelector(selectors.getFetchingElement);
    const saving = useSelector(selectors.getSaving);
    const deleting = useSelector(selectors.getDeleting);
    const idModal = useSelector(selectors.getIdModal);
    const file = useSelector(selectors.getFile, stringifyEqualityCheck);
    const uploadingFile = useSelector(selectors.getUploadingFile);
    const year = useSelector(selectors.getYear);
    const month = useSelector(selectors.getMonth);
    const deleteType = useSelector(selectors.getDeleteType);
    const filters = useSelector(selectors.getFilters, stringifyEqualityCheck);
    const oldFilters = useSelector(selectors.getOldFilters, stringifyEqualityCheck);
    const exportingExcel = useSelector(selectors.getExportingExcel);
    const filtersOptions = useSelector(selectors.getFiltersOptions, stringifyEqualityCheck);
    const total = useSelector(selectors.getTotal);
    const fileType = useSelector(selectors.getFileType);
    const formType = useSelector(selectors.getFormType);
    const dataType = useSelector(layoutSelectors.getDataType);
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const setActionType = useCallback((value) => dispatch(actions.setActionType(value)), [dispatch]);
    const setActivePage = useCallback((value) => dispatch(actions.setActivePage(value)), [dispatch]);
    const fetchElements = useCallback(() => dispatch(actions.fetchElements()), [dispatch]);
    const fetchElement = useCallback((id) => dispatch(actions.fetchElement(id)), [dispatch]);
    const saveElement = useCallback(() => dispatch(actions.saveElement()), [dispatch]);
    const setField = useCallback((field, value) => dispatch(actions.setField(field, value)), [dispatch]);
    const setHistory = useCallback((value) => dispatch(actions.setHistory(value)), [dispatch]);
    const setSort = useCallback((column, type, orderType) => dispatch(actions.setSort(column, type, orderType)), [dispatch]);
    const setSearch = useCallback((value) => dispatch(actions.setSearch(value)), [dispatch]);
    const setSearchType = useCallback((value) => dispatch(actions.setSearchType(value)), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);
    const setIdModal = useCallback((id) => dispatch(actions.setIdModal(id)), [dispatch]);
    const deleteItem = useCallback(() => dispatch(actions.deleteItem()), [dispatch]);
    const uploadFile = useCallback(() => dispatch(actions.uploadFile()), [dispatch]);
    const setFile = useCallback((file) => dispatch(actions.setFile(file)), [dispatch]);
    const setYear = useCallback((value) => dispatch(actions.setYear(value)), [dispatch]);
    const setMonth = useCallback((value) => dispatch(actions.setMonth(value)), [dispatch]);
    const setDeleteType = useCallback((value) => dispatch(actions.setDeleteType(value)), [dispatch]);
    const setFilter = useCallback((name, value) => dispatch(actions.setFilter(name, value)), [dispatch]);
    const exportExcel = useCallback(() => dispatch(actions.exportExcel()), [dispatch]);
    const setFileType = useCallback((value) => dispatch(actions.setFileType(value)), [dispatch]);
    const setFormType = useCallback((value) => dispatch(actions.setFormType(value)), [dispatch]);
    const clearFilters = useCallback(() => dispatch(actions.clearFilters()), [dispatch]);
    const showDeleteModal = useCallback(() => dispatch(dialog.actions.showDialog('delete-modal')), [dispatch]);
    const hideDeleteModal = useCallback(() => dispatch(dialog.actions.hideDialog('delete-modal')), [dispatch]);
    const showUploadFileModal = useCallback(() => dispatch(dialog.actions.showDialog('upload-file-modal')), [dispatch]);
    const hideUploadFileModal = useCallback(() => dispatch(dialog.actions.hideDialog('upload-file-modal')), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        window.scrollTo(0, 0);
        setHistory(props.history);

        if (props.match.params.action) {
            setActionType(props.match.params.action);
            setFormType(props.match.params.type);

            if (props.match.params.action === 'edit') {
                fetchElement(props.match.params.id);
            }
        } else {
            setYear(moment().format('YYYY'));
            setMonth(parseInt(moment().format('MM')));

            setActionType('list');
            fetchElements();
        }

        return () => {
            resetState();
        };
    }, [setActionType, resetState, fetchElements, fetchElement, setHistory, setYear, setMonth, setFormType,
        props.history, props.match.params.action, props.match.params.id, props.match.params.type]);

    if (user == null) {
        return <Redirect to="/login" />;
    }

    const handleChangeYear = (event) => {
        setYear(event.target.value);
    };

    const handleChangeMonth = (event) => {
        setMonth(event.target.value);
    };

    const renderYears = () => {
        const options = [];
        for (let i = 2019; i <= 2040; i++) {
            options.push(<option value={i}>{i}</option>);
        }
        return options;
    };

    let titleContainer = null;
    if (parseInt(user.type) === 1 || parseInt(user.type) === 2) {   // Super Admin, Admin
        titleContainer = (
            <Fragment>
                <h1>Transactions</h1>
                <div className="header-actions-container">
                    <div className="search-field">
                        <div className="month-field">
                            <select value={year} onChange={handleChangeYear} className="mr-1 year-select">
                                <option value="">Select Year</option>
                                {renderYears()}
                            </select>
                            <select value={month} onChange={handleChangeMonth} className="month-select">
                                <option value="">Select Month</option>
                                <option value="1">January</option>
                                <option value="2">February</option>
                                <option value="3">March</option>
                                <option value="4">April</option>
                                <option value="5">May</option>
                                <option value="6">June</option>
                                <option value="7">July</option>
                                <option value="8">August</option>
                                <option value="9">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                            </select>
                            <button className="search-button admin-button" onClick={fetchElements} disabled={fetchingElements}>
                                {fetchingElements ? <i className="fas fa-circle-notch fa-spin" /> : 'Select'}
                            </button>
                        </div>
                    </div>
                    <div className="search-field-mobile">
                        <div className="month-field">
                            <select value={year} onChange={handleChangeYear} className="mr-1 year-select">
                                <option value="">Select Year</option>
                                {renderYears()}
                            </select>
                            <select value={month} onChange={handleChangeMonth} className="month-select">
                                <option value="">Select Month</option>
                                <option value="1">January</option>
                                <option value="2">February</option>
                                <option value="3">March</option>
                                <option value="4">April</option>
                                <option value="5">May</option>
                                <option value="6">June</option>
                                <option value="7">July</option>
                                <option value="8">August</option>
                                <option value="9">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                            </select>
                            <button className="search-button admin-button" onClick={fetchElements} disabled={fetchingElements}>
                                {fetchingElements ? <i className="fas fa-circle-notch fa-spin" /> : 'Select'}
                            </button>
                        </div>
                    </div>
                    <div className="new-button-container">
                        <button className="admin-button upload-file-button" onClick={exportExcel} disabled={exportingExcel}>
                            {exportingExcel ? <i className="fas fa-circle-notch fa-spin" /> : 'Export Excel'}
                        </button>
                        <button className="admin-button upload-file-button" onClick={showUploadFileModal}>Upload File</button>
                        <Link to={'/transactions/new/' + dataType}><button className="admin-button">Add New</button></Link>
                    </div>
                </div>
            </Fragment>
        );
    } else if (parseInt(user.type) === 3) {    // Viewer
        titleContainer = (
            <Fragment>
                <h1>Transactions</h1>
                <div className="header-actions-container">
                    <div className="search-field">
                        <div className="month-field">
                            <select value={year} onChange={handleChangeYear} className="mr-1 year-select">
                                <option value="">Select Year</option>
                                {renderYears()}
                            </select>
                            <select value={month} onChange={handleChangeMonth} className="month-select">
                                <option value="">Select Month</option>
                                <option value="1">January</option>
                                <option value="2">February</option>
                                <option value="3">March</option>
                                <option value="4">April</option>
                                <option value="5">May</option>
                                <option value="6">June</option>
                                <option value="7">July</option>
                                <option value="8">August</option>
                                <option value="9">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                            </select>
                            <button className="search-button admin-button" onClick={fetchElements} disabled={fetchingElements}>
                                {fetchingElements ? <i className="fas fa-circle-notch fa-spin" /> : 'Select'}
                            </button>
                        </div>
                    </div>
                    <div className="search-field-mobile">
                        <div className="month-field">
                            <select value={year} onChange={handleChangeYear} className="mr-1 year-select">
                                <option value="">Select Year</option>
                                {renderYears()}
                            </select>
                            <select value={month} onChange={handleChangeMonth} className="month-select">
                                <option value="">Select Month</option>
                                <option value="1">January</option>
                                <option value="2">February</option>
                                <option value="3">March</option>
                                <option value="4">April</option>
                                <option value="5">May</option>
                                <option value="6">June</option>
                                <option value="7">July</option>
                                <option value="8">August</option>
                                <option value="9">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                            </select>
                            <button className="search-button admin-button" onClick={fetchElements} disabled={fetchingElements}>
                                {fetchingElements ? <i className="fas fa-circle-notch fa-spin" /> : 'Select'}
                            </button>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    } else if (parseInt(user.type) === 4) {   // Admin Viewer
        titleContainer = (
            <Fragment>
                <h1>Transactions</h1>
                <div className="header-actions-container">
                    <div className="search-field">
                        <div className="month-field">
                            <select value={year} onChange={handleChangeYear} className="mr-1 year-select">
                                <option value="">Select Year</option>
                                {renderYears()}
                            </select>
                            <select value={month} onChange={handleChangeMonth} className="month-select">
                                <option value="">Select Month</option>
                                <option value="1">January</option>
                                <option value="2">February</option>
                                <option value="3">March</option>
                                <option value="4">April</option>
                                <option value="5">May</option>
                                <option value="6">June</option>
                                <option value="7">July</option>
                                <option value="8">August</option>
                                <option value="9">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                            </select>
                            <button className="search-button admin-button" onClick={fetchElements} disabled={fetchingElements}>
                                {fetchingElements ? <i className="fas fa-circle-notch fa-spin" /> : 'Select'}
                            </button>
                        </div>
                    </div>
                    <div className="search-field-mobile">
                        <div className="month-field">
                            <select value={year} onChange={handleChangeYear} className="mr-1 year-select">
                                <option value="">Select Year</option>
                                {renderYears()}
                            </select>
                            <select value={month} onChange={handleChangeMonth} className="month-select">
                                <option value="">Select Month</option>
                                <option value="1">January</option>
                                <option value="2">February</option>
                                <option value="3">March</option>
                                <option value="4">April</option>
                                <option value="5">May</option>
                                <option value="6">June</option>
                                <option value="7">July</option>
                                <option value="8">August</option>
                                <option value="9">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                            </select>
                            <button className="search-button admin-button" onClick={fetchElements} disabled={fetchingElements}>
                                {fetchingElements ? <i className="fas fa-circle-notch fa-spin" /> : 'Select'}
                            </button>
                        </div>
                    </div>
                    <div className="new-button-container">
                        <button className="admin-button upload-file-button" onClick={exportExcel} disabled={exportingExcel}>
                            {exportingExcel ? <i className="fas fa-circle-notch fa-spin" /> : 'Export Excel'}
                        </button>
                    </div>
                </div>
            </Fragment>
        );
    } else if (parseInt(user.type) === 5) {   // Super Viewer
        titleContainer = (
            <Fragment>
                <h1>Transactions</h1>
                <div className="header-actions-container">
                    <div className="search-field">
                        <div className="month-field">
                            <select value={year} onChange={handleChangeYear} className="mr-1 year-select">
                                <option value="">Select Year</option>
                                {renderYears()}
                            </select>
                            <select value={month} onChange={handleChangeMonth} className="month-select">
                                <option value="">Select Month</option>
                                <option value="1">January</option>
                                <option value="2">February</option>
                                <option value="3">March</option>
                                <option value="4">April</option>
                                <option value="5">May</option>
                                <option value="6">June</option>
                                <option value="7">July</option>
                                <option value="8">August</option>
                                <option value="9">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                            </select>
                            <button className="search-button admin-button" onClick={fetchElements} disabled={fetchingElements}>
                                {fetchingElements ? <i className="fas fa-circle-notch fa-spin" /> : 'Select'}
                            </button>
                        </div>
                    </div>
                    <div className="search-field-mobile">
                        <div className="month-field">
                            <select value={year} onChange={handleChangeYear} className="mr-1 year-select">
                                <option value="">Select Year</option>
                                {renderYears()}
                            </select>
                            <select value={month} onChange={handleChangeMonth} className="month-select">
                                <option value="">Select Month</option>
                                <option value="1">January</option>
                                <option value="2">February</option>
                                <option value="3">March</option>
                                <option value="4">April</option>
                                <option value="5">May</option>
                                <option value="6">June</option>
                                <option value="7">July</option>
                                <option value="8">August</option>
                                <option value="9">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                            </select>
                            <button className="search-button admin-button" onClick={fetchElements} disabled={fetchingElements}>
                                {fetchingElements ? <i className="fas fa-circle-notch fa-spin" /> : 'Select'}
                            </button>
                        </div>
                    </div>
                    <div className="new-button-container">
                        <button className="admin-button upload-file-button" onClick={exportExcel} disabled={exportingExcel}>
                            {exportingExcel ? <i className="fas fa-circle-notch fa-spin" /> : 'Export Excel'}
                        </button>
                        <button className="admin-button upload-file-button" onClick={showUploadFileModal}>Upload File</button>
                        <Link to={'/transactions/new/' + dataType}><button className="admin-button">Add New</button></Link>
                    </div>
                </div>
            </Fragment>
        );
    }

    if (props.match.params.action === 'new') {
        titleContainer = (
            <h1>New Transaction</h1>
        );
    } else if (props.match.params.action === 'edit') {
        titleContainer = (
            <h1>Edit Transaction</h1>
        );
    }

    let formModal = null;
    let deleteModal = null;
    let uploadModal = null;
    if (parseInt(user.type) === 1 || parseInt(user.type) === 2) {   // Super Admin, Admin
        formModal = (
            <Form
                actionType={actionType}
                item={item}
                saving={saving}
                fetchingElement={fetchingElement}
                formType={formType}
                setField={setField}
                saveElement={saveElement}
            />
        );
        deleteModal = (
            <DeleteModal
                items={items}
                idModal={idModal}
                deleting={deleting}
                deleteType={deleteType}
                deleteItem={deleteItem}
                hideDeleteModal={hideDeleteModal}
                setIdModal={setIdModal}
                setDeleteType={setDeleteType}
            />
        );
        uploadModal = (
            <UploadFileModal
                file={file}
                uploadingFile={uploadingFile}
                fileType={fileType}
                setFileType={setFileType}
                setFile={setFile}
                uploadFile={uploadFile}
                hideUploadFileModal={hideUploadFileModal}
            />
        );
    } else if (parseInt(user.type) === 5) {   // Super Viewer
        formModal = (
            <Form
                actionType={actionType}
                item={item}
                saving={saving}
                fetchingElement={fetchingElement}
                formType={formType}
                setField={setField}
                saveElement={saveElement}
            />
        );
        uploadModal = (
            <UploadFileModal
                file={file}
                uploadingFile={uploadingFile}
                fileType={fileType}
                setFileType={setFileType}
                setFile={setFile}
                uploadFile={uploadFile}
                hideUploadFileModal={hideUploadFileModal}
            />
        );
    }

    const setDataTypeCallback = () => {
        clearFilters();
        fetchElements();
    };

    return (
        <AdminLayout history={props.history} setDataTypeCallback={setDataTypeCallback}>
            <Helmet>
                <title>EPS Transactions</title>
            </Helmet>
            <div className="records-container">
                {titleContainer}
                <Table
                    pagination={pagination}
                    actionType={actionType}
                    sort={sort}
                    search={search}
                    searchType={searchType}
                    fetchingElements={fetchingElements}
                    items={items}
                    filters={filters}
                    oldFilters={oldFilters}
                    filtersOptions={filtersOptions}
                    user={user}
                    total={total}
                    dataType={dataType}
                    setActivePage={setActivePage}
                    setSort={setSort}
                    setSearch={setSearch}
                    setSearchType={setSearchType}
                    showDeleteModal={showDeleteModal}
                    setIdModal={setIdModal}
                    setFilter={setFilter}
                    fetchElements={fetchElements}
                />
                {formModal}
            </div>
            {deleteModal}
            {uploadModal}
        </AdminLayout>
    );
};

export default Records;
