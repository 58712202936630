import Immutable from 'immutable';
import * as types from './AdminLayout.types';

const initialState = {
    showSidebarMenu: false,
    dataType: 1,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.SET_SHOW_SIDEBAR_MENU:
            return state.setIn(['showSidebarMenu'], action.payload).toJS();

        case types.SET_DATA_TYPE:
            return state.setIn(['dataType'], action.payload).toJS();

        default:
            return state.toJS();
    }
}