export const FETCH_ELEMENTS = 'RECORDS/FETCH_ELEMENTS';
export const FETCH_ELEMENTS_SUCCESS = 'RECORDS/FETCH_ELEMENTS_SUCCESS';
export const FETCH_ELEMENTS_ERROR = 'RECORDS/FETCH_ELEMENTS_ERROR';
export const FETCH_ELEMENT = 'RECORDS/FETCH_ELEMENT';
export const FETCH_ELEMENT_SUCCESS = 'RECORDS/FETCH_ELEMENT_SUCCESS';
export const FETCH_ELEMENT_ERROR = 'RECORDS/FETCH_ELEMENT_ERROR';
export const SAVE_ELEMENT = 'RECORDS/SAVE_ELEMENT';
export const SAVE_ELEMENT_SUCCESS = 'RECORDS/SAVE_ELEMENT_SUCCESS';
export const SAVE_ELEMENT_ERROR = 'RECORDS/SAVE_ELEMENT_ERROR';
export const DELETE_ITEM = 'RECORDS/DELETE_ITEM';
export const DELETE_ITEM_SUCCESS = 'RECORDS/DELETE_ITEM_SUCCESS';
export const DELETE_ITEM_ERROR = 'RECORDS/DELETE_ITEM_ERROR';
export const UPLOAD_FILE = 'RECORDS/UPLOAD_FILE';
export const UPLOAD_FILE_SUCCESS = 'RECORDS/UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_ERROR = 'RECORDS/UPLOAD_FILE_ERROR';
export const EXPORT_EXCEL = 'RECORDS/EXPORT_EXCEL';
export const EXPORT_EXCEL_SUCCESS = 'RECORDS/EXPORT_EXCEL_SUCCESS';
export const EXPORT_EXCEL_ERROR = 'RECORDS/EXPORT_EXCEL_ERROR';
export const SET_FIELD = 'RECORDS/SET_FIELD';
export const SET_ACTION_TYPE = 'RECORDS/SET_ACTION_TYPE';
export const SET_ACTIVE_PAGE = 'RECORDS/SET_ACTIVE_PAGE';
export const SET_SORT = 'RECORDS/SET_SORT';
export const SET_SEARCH = 'RECORDS/SET_SEARCH';
export const SET_SEARCH_TYPE = 'RECORDS/SET_SEARCH_TYPE';
export const SET_FILE = 'RECORDS/SET_FILE';
export const SET_START_DATE = 'RECORDS/SET_START_DATE';
export const SET_END_DATE = 'RECORDS/SET_END_DATE';
export const SET_YEAR = 'RECORDS/SET_YEAR';
export const SET_MONTH = 'RECORDS/SET_MONTH';
export const SET_SEARCHED_YEAR = 'RECORDS/SET_SEARCHED_YEAR';
export const SET_SEARCHED_MONTH = 'RECORDS/SET_SEARCHED_MONTH';
export const SET_DELETE_TYPE = 'RECORDS/SET_DELETE_TYPE';
export const SET_FILTER = 'RECORDS/SET_FILTER';
export const SET_OLD_FILTERS = 'RECORDS/SET_OLD_FILTERS';
export const SET_FILE_TYPE = 'RECORDS/SET_FILE_TYPE';
export const SET_FORM_TYPE = 'RECORDS/SET_FORM_TYPE';
export const CLEAR_FILTERS = 'RECORDS/CLEAR_FILTERS';
export const SET_HISTORY = 'RECORDS/SET_HISTORY';
export const SET_ID_MODAL = 'RECORDS/SET_ID_MODAL';
export const RESET_STATE = 'RECORDS/RESET_STATE';