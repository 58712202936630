import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { Provider } from 'react-redux';
import store from './store';
import ReactNotification from 'react-notifications-component';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import 'react-notifications-component/dist/theme.css';
import './index.scss';
import AdminUsers from './components/AdminUsers';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import Records from './components/Records';

require('dotenv').config();

if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });
}

ReactDOM.render(
    <Provider store={store}>
        <ReactNotification />
        <Router>
            {/* <Route path="/" exact component={Landing} /> */}
            <Route path="/login" exact component={Login} />
            <Route path="/" exact component={Dashboard} />
            <Route path="/transactions" exact component={Records} />
            <Route path="/transactions/:action/:type" exact component={Records} />
            <Route path="/transactions/:action/:type/:id" exact component={Records} />
            <Route path="/users" exact component={AdminUsers} />
            <Route path="/users/:action" exact component={AdminUsers} />
            <Route path="/users/:action/:id" exact component={AdminUsers} />
        </Router>
    </Provider>,
    document.getElementById('root')
);
