import Immutable from 'immutable';
import * as types from './Login.types';

const initialState = {
    user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
    access_token: localStorage.getItem('access_token'),
    refresh_token: localStorage.getItem('refresh_token'),
    isAuthenticated: localStorage.getItem('access_token') !== null,
    form: { email: '', password: '' },
    isLogging: false,
    type: 'frontend',
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.SET_FIELD:
            return state.setIn(['form', action.payload.name], action.payload.value).toJS();

        case types.LOGIN:
            return state.setIn(['isLogging'], true).toJS();

        case types.LOGIN_SUCCESS:
            return state.setIn(['isLogging'], false)
                .setIn(['form'], { email: '', password: '' })
                .toJS();

        case types.LOGIN_ERROR:
            return state.setIn(['isLogging'], false).toJS();

        case types.SET_TYPE:
            return state.setIn(['type'], action.payload).toJS();

        case types.SET_TOKENS:
            return state.setIn(['user'], action.payload.user)
                .setIn(['access_token'], action.payload.access_token)
                .setIn(['refresh_token'], action.payload.refresh_token)
                .setIn(['isAuthenticated'], true)
                .toJS();

        case types.CLEAR_TOKENS:
            return state.setIn(['user'], null)
                .setIn(['access_token'], null)
                .setIn(['refresh_token'], null)
                .setIn(['isAuthenticated'], false)
                .toJS();

        default:
            return state.toJS();
    }
}