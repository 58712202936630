import React from 'react';
import { Link } from 'react-router-dom';

const Form = (props) => {
    if (props.actionType === 'list') {
        return null;
    }

    if (props.fetchingElement) {
        return (
            <div className="form-container">
                <div className="loading-container">
                    <i className="fas fa-circle-notch fa-spin" /> Loading data...
                </div>
            </div>
        );
    }

    const handleSetField = (field, event) => {
        let value = event.target.value;
        if (event.target.type === 'checkbox') {
            value = event.target.checked ? 1 : 0;
        }

        props.setField(field, value);
    };

    const renderField = (type, label, field) => {
        return (
            <div className="field">
                <div className="label">{label}:</div>
                <input type={type} value={props.item[field]} onChange={handleSetField.bind(this, field)} placeholder={label} />
            </div>
        );
    };

    const renderAllTransactionsForm = () => {
        if (parseInt(props.formType) !== 1) {
            return;
        }

        return (
            <div className="fields">
                {renderField('text', 'Account #', 'account_number')}
                {renderField('text', 'Transaction #', 'transaction_number')}
                {renderField('text', 'Permit / Pub', 'permit_pub')}
                {renderField('text', 'CRID', 'crid')}
                {renderField('text', 'PO of Permit', 'po_of_permit')}
                {renderField('text', 'PO of Mailing', 'po_of_mailing')}
                {renderField('date', 'Date', 'date')}
                {renderField('text', 'Transaction Type', 'transaction_type')}
                {renderField('text', 'Customer Reference ID', 'customer_reference_id')}
                {renderField('text', 'CAPS Tran #', 'caps_tran_number')}
                {renderField('text', 'EPS Tran #', 'eps_tran_number')}
                {renderField('number', 'Beggining Balance', 'beginning_balance')}
                {renderField('number', 'Amount', 'amount')}
                {renderField('number', 'Ending Balance', 'ending_balance')}
                {renderField('number', 'Pieces', 'pieces')}
                {renderField('text', 'User', 'user')}
                {renderField('date', 'Open Date', 'open_date')}
                {renderField('date', 'Mailer Mailing Date', 'mailer_mailing_date')}
                {renderField('date', 'Certification Date', 'certification_date')}
                {renderField('text', 'Mailing Group ID', 'mailing_group_id')}
                {renderField('text', 'Job ID', 'job_id')}
                {renderField('text', 'Job Description', 'job_description')}
                {renderField('number', 'Containers', 'containers')}
                {renderField('text', 'Stage', 'stage')}
                {renderField('text', 'Mailing Agent', 'mailing_agent')}
            </div>
        );
    };

    const renderDailyEpsForm = () => {
        if (parseInt(props.formType) !== 2) {
            return;
        }

        return (
            <div className="fields">
                {renderField('text', 'Transaction #', 'transaction_id')}
                {renderField('date', 'Date', 'date')}
                {renderField('date', 'Due Date', 'due_date')}
                {renderField('number', 'Amount', 'amount')}
                {renderField('text', 'Transaction Type', 'transaction_type')}
                {renderField('text', 'Description', 'description')}
                {renderField('text', 'Detail', 'detail')}
                {renderField('text', 'EPS Account Number', 'eps_account_number')}
                {renderField('text', 'EPS Account Nickname', 'eps_account_nickname')}
                {renderField('text', 'Business Location', 'business_location')}
                {renderField('text', 'Payment Method', 'payment_method')}
                {renderField('text', 'Original Transaction ID', 'original_transaction_id')}
                {renderField('text', 'Available Balance', 'available_balance')}
                {renderField('text', 'ACH Withdrawal ID', 'ach_withdrawal_id')}
                {renderField('text', 'Permit #', 'permit_number')}
                {renderField('text', 'Permit Type', 'permit_type')}
            </div>
        );
    };

    return (
        <div className="form-container">
            {renderAllTransactionsForm()}
            {renderDailyEpsForm()}
            <div className="buttons-container">
                <div className="button-container">
                    <Link to="/transactions">
                        <button className="cancel-button">Cancel</button>
                    </Link>
                </div>
                <div className="button-container">
                    <button className="save-button admin-button" onClick={props.saveElement} disabled={props.saving}>
                        {props.saving ? <i className="fas fa-circle-notch fa-spin" /> : 'Save'}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Form;
