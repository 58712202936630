import Immutable from 'immutable';
import * as types from './Records.types';

const initialState = {
    actionType: 'list',
    items: [],
    item: {
        is_active: 1,
    },
    fetchingElements: false,
    fetchingElement: false,
    saving: false,
    deleting: false,
    idModal: null,
    pagination: {
        totalElements: 0,
        activePage: 1,
        totalPages: 0,
        elementsPerPage: 20,
    },
    sort: {
        column: 'date',
        type: 'desc',
    },
    search: '',
    searchType: 'date',
    file: null,
    uploadingFile: false,
    startDate: '',
    endDate: '',
    year: '',
    month: '',
    deleteType: '1',
    filters: {},
    oldFilters: {},
    exportingExcel: false,
    filtersOptions: {
        transactionTypes: [],
        permits: [],
    },
    total: 0,
    fileType: 1,
    formType: 1,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.FETCH_ELEMENTS:
            return state.setIn(['fetchingElements'], true).toJS();

        case types.FETCH_ELEMENTS_SUCCESS:
            return state.setIn(['fetchingElements'], false)
                .setIn(['items'], action.payload.data.elements)
                .setIn(['pagination', 'totalElements'], action.payload.data.totalElements)
                .setIn(['pagination', 'activePage'], action.payload.data.activePage)
                .setIn(['pagination', 'totalPages'], action.payload.data.totalPages)
                .setIn(['pagination', 'elementsPerPage'], action.payload.data.elementsPerPage)
                .setIn(['filtersOptions'], action.payload.filtersOptions)
                .setIn(['total'], action.payload.total)
                .toJS();

        case types.FETCH_ELEMENTS_ERROR:
            return state.setIn(['fetchingElements'], false).toJS();

        case types.FETCH_ELEMENT:
            return state.setIn(['fetchingElement'], true).toJS();

        case types.FETCH_ELEMENT_SUCCESS:
            return state.setIn(['fetchingElement'], false)
                .setIn(['item'], action.payload)
                .toJS();

        case types.FETCH_ELEMENT_ERROR:
            return state.setIn(['fetchingElement'], false).toJS();

        case types.SAVE_ELEMENT:
            return state.setIn(['saving'], true).toJS();

        case types.SAVE_ELEMENT_SUCCESS:
            return state.setIn(['saving'], false)
                .setIn(['item'], { is_active: 1 })
                .toJS();

        case types.SAVE_ELEMENT_ERROR:
            return state.setIn(['saving'], false).toJS();

        case types.DELETE_ITEM:
            return state.setIn(['deleting'], true).toJS();

        case types.DELETE_ITEM_SUCCESS:
            return state.setIn(['deleting'], false)
                .setIn(['idModal'], null)
                .setIn(['deleteType'], 1)
                .toJS();

        case types.DELETE_ITEM_ERROR:
            return state.setIn(['deleting'], false).toJS();

        case types.EXPORT_EXCEL:
            return state.setIn(['exportingExcel'], true).toJS();

        case types.EXPORT_EXCEL_SUCCESS:
            return state.setIn(['exportingExcel'], false).toJS();

        case types.EXPORT_EXCEL_ERROR:
            return state.setIn(['exportingExcel'], false).toJS();

        case types.UPLOAD_FILE:
            return state.setIn(['uploadingFile'], true).toJS();

        case types.UPLOAD_FILE_SUCCESS:
            return state.setIn(['uploadingFile'], false)
                .setIn(['file'], null)
                .toJS();

        case types.UPLOAD_FILE_ERROR:
            return state.setIn(['uploadingFile'], false).toJS();

        case types.SET_ACTION_TYPE:
            return state.setIn(['actionType'], action.payload).toJS();

        case types.SET_ACTIVE_PAGE:
            return state.setIn(['pagination', 'activePage'], action.payload).toJS();

        case types.SET_SORT:
            return state.setIn(['sort', 'type'], action.payload.type)
                .setIn(['sort', 'column'], action.payload.column)
                .toJS();

        case types.SET_SEARCH:
            return state.setIn(['search'], action.payload).toJS();

        case types.SET_SEARCH_TYPE:
            return state.setIn(['searchType'], action.payload).toJS();

        case types.SET_FIELD:
            return state.setIn(['item', action.payload.field], action.payload.value).toJS();

        case types.SET_FILE:
            return state.setIn(['file'], action.payload).toJS();

        case types.SET_START_DATE:
            return state.setIn(['startDate'], action.payload).toJS();

        case types.SET_END_DATE:
            return state.setIn(['endDate'], action.payload).toJS();

        case types.SET_YEAR:
            return state.setIn(['year'], action.payload).toJS();

        case types.SET_MONTH:
            return state.setIn(['month'], action.payload).toJS();

        case types.SET_DELETE_TYPE:
            return state.setIn(['deleteType'], action.payload).toJS();

        case types.SET_FILTER:
            return state.setIn(['filters', action.payload.name], action.payload.value).toJS();

        case types.SET_OLD_FILTERS:
            return state.setIn(['oldFilters'], action.payload).toJS();

        case types.SET_FILE_TYPE:
            return state.setIn(['fileType'], action.payload).toJS();

        case types.SET_FORM_TYPE:
            return state.setIn(['formType'], action.payload).toJS();

        case types.CLEAR_FILTERS:
            return state.setIn(['filters'], {})
                .setIn(['oldFilters'], {})
                .toJS();

        case types.SET_HISTORY:
            return state.setIn(['history'], action.payload).toJS();

        case types.SET_ID_MODAL:
            return state.setIn(['idModal'], action.payload).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}