import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './Dashboard.scss';
import Charts from './Charts';
import AdminLayout from './../AdminLayout';
import * as actions from './Dashboard.actions';
import * as selectors from './Dashboard.selectors';
import * as layoutSelectors from './../AdminLayout/AdminLayout.selectors';
import * as layoutActions from './../AdminLayout/AdminLayout.actions';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { stringifyEqualityCheck } from './../../utils';

const Dashboard = (props) => {
    /* State to props */
    const fetchingData = useSelector(selectors.getFetchingData);
    const year = useSelector(selectors.getYear);
    const month = useSelector(selectors.getMonth);
    const data = useSelector(selectors.getData, stringifyEqualityCheck);
    const row = useSelector(selectors.getRow);
    const dataType = useSelector(layoutSelectors.getDataType);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const fetchData = useCallback(() => dispatch(actions.fetchData()), [dispatch]);
    const setYear = useCallback((value) => dispatch(actions.setYear(value)), [dispatch]);
    const setMonth = useCallback((value) => dispatch(actions.setMonth(value)), [dispatch]);
    const setRow = useCallback((value) => dispatch(actions.setRow(value)), [dispatch]);
    const setChartType = useCallback((index, value) => dispatch(actions.setChartType(index, value)), [dispatch]);
    const setHistory = useCallback((value) => dispatch(actions.setHistory(value)), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);
    const setDefaultChart = useCallback((rowNumber, value) => dispatch(layoutActions.setDefaultChart(rowNumber, value)), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        window.scrollTo(0, 0);
        setHistory(props.history);
        
        setYear(moment().format('YYYY'));
        setMonth(parseInt(moment().format('MM')));

        setRow(props.match.params.row);

        fetchData();

        return () => {
            resetState();
        };
    }, [fetchData, setYear, setMonth, setRow, resetState, setHistory,
        props.history, props.match.params.row]);

    const handleChangeYear = (event) => {
        setYear(event.target.value);
    };

    const handleChangeMonth = (event) => {
        setMonth(event.target.value);
    };

    const renderYears = () => {
        const options = [];
        for (let i = 2019; i <= 2040; i++) {
            options.push(<option value={i}>{i}</option>);
        }
        return options;
    };

    return (
        <AdminLayout history={props.history} setDataTypeCallback={fetchData}>
            <Helmet>
                <title>EPS Dashboard</title>
            </Helmet>
            <div className="dashboard-container">
                <h1>Dashboard</h1>
                <div className="header-actions-container">
                    <div className="search-field">
                        <select value={year} onChange={handleChangeYear}>
                            <option value="">Select Year</option>
                            {renderYears()}
                        </select>
                        <select value={month} onChange={handleChangeMonth}>
                            <option value="">Select Month</option>
                            <option value="1">January</option>
                            <option value="2">February</option>
                            <option value="3">March</option>
                            <option value="4">April</option>
                            <option value="5">May</option>
                            <option value="6">June</option>
                            <option value="7">July</option>
                            <option value="8">August</option>
                            <option value="9">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                        </select>
                        <button className="search-button admin-button" onClick={fetchData} disabled={fetchingData}>
                            {fetchingData ? <i className="fas fa-circle-notch fa-spin" /> : 'Search'}
                        </button>
                    </div>
                </div>
                <Charts
                    data={data}
                    fetchingData={fetchingData}
                    year={year}
                    month={month}
                    row={row}
                    dataType={dataType}
                    setChartType={setChartType}
                    setDefaultChart={setDefaultChart}
                />
            </div>
        </AdminLayout>
    );
};

export default Dashboard;
