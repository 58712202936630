import React from 'react';
import moment from 'moment';
import { Dialog } from './../../common/dialog';

const DeleteModal = React.memo((props) => {
    const hideHandler = () => {
        props.setIdModal(null);
        props.hideDeleteModal();
    };

    const deleteHandler = () => {
        props.deleteItem(props.history);
    };

    const handleSetDeleteType = (event) => {
        props.setDeleteType(event.target.value);
    };

    let selectedItem = null;
    for (const item of props.items) {
        if (parseInt(item.id) === parseInt(props.idModal)) {
            selectedItem = item;
            break;
        }
    }

    let date = moment();
    if (selectedItem != null) {
        date = moment(selectedItem.date);
    }

    let label = 'Delete';
    if (props.deleting) {
        label = <i className="fas fa-circle-notch fa-spin" />;
    }

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideHandler}>Cancel</button>,
        <button key="delete-button" className="btn btn-light btn-sm action-button" onClick={deleteHandler} disabled={props.deleting}>{label}</button>
    ];
    return (
        <Dialog
            name="delete-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="records-delete-dialog"
        >
            <div className="records-delete-modal-container">
                <h1>Delete Item?</h1>
                <p className="notice">
                    <div>Select the Delete Type and confirm that you want to delete the element(s).</div>
                    <div className="field">
                        <b>Delete Type:</b>
                        <select value={props.deleteType} onChange={handleSetDeleteType}>
                            <option value="1">This item only</option>
                            <option value="2">All items from this date ({date.format('MM/DD/YYYY')})</option>
                            <option value="3">All items from this month ({date.format('MMMM')})</option>
                        </select>
                    </div>
                </p>
            </div>
        </Dialog >
    );
});

export default DeleteModal;
