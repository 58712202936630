import React, { Fragment } from 'react';
import Pagination from './../../Pagination';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import moment from 'moment';
import numeral from 'numeral';
import { empty } from './../../../utils';

const Table = (props) => {
    if (props.actionType !== 'list') {
        return null;
    }

    const handleDeleteModal = (id) => {
        props.setIdModal(id);
        props.showDeleteModal();
    };

    const renderAllTransactionsElements = () => {
        if (parseInt(props.dataType) !== 1) {
            return;
        }

        if (props.fetchingElements) {
            return (
                <tr>
                    <td colSpan="14"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (props.items.length === 0) {
            return (
                <tr>
                    <td colSpan="14">No results</td>
                </tr>
            );
        }

        let rows = props.items.map((item) => {
            let editIcon = (
                <div className="action-icon">
                    <Tooltip title="Edit" position="bottom" arrow size="small" className="action-tooltip">
                        <Link to={'/transactions/edit/1/' + item.id}><i className="fa-solid fa-pen-to-square"></i></Link>
                    </Tooltip>
                </div>
            );
            let deleteIcon = (
                <div className="action-icon">
                    <Tooltip title="Delete" position="bottom" arrow size="small" className="action-tooltip">
                        <i className="fa-solid fa-trash" onClick={handleDeleteModal.bind(this, item.id)}></i>
                    </Tooltip>
                </div>
            );

            let mobileActions = null;
            let actions = null;
            if (parseInt(props.user.type) === 1 || parseInt(props.user.type) === 2) {   // Super Admin, Admin
                mobileActions = (
                    <div className="description-actions">
                        {editIcon}
                        {deleteIcon}
                    </div>
                );
                actions = (
                    <td className="actions-column desktop-column">
                        <div className="actions-container">
                            {editIcon}
                            {deleteIcon}
                        </div>
                    </td>
                );
            }

            return (
                <tr>
                    <td className="permit-column">
                        <div className="name">{item.permit_pub}</div>
                        <div className="description">
                            <div className="description-item">
                                <div className="label">Permit / Pub:</div>
                                <div className="value">{item.permit_pub}</div>
                            </div>
                            <div className="description-item">
                                <div className="label">CRID:</div>
                                <div className="value">{item.crid}</div>
                            </div>
                            <div className="description-item">
                                <div className="label">Transaction Type:</div>
                                <div className="value">{item.transaction_type}</div>
                            </div>
                            <div className="description-item">
                                <div className="label">Transaction #:</div>
                                <div className="value">{item.transaction_number}</div>
                            </div>
                            <div className="description-item">
                                <div className="label">Date:</div>
                                <div className="value">{moment(item.date).format('MM/DD/YYYY')}</div>
                            </div>
                            <div className="description-item">
                                <div className="label">Job ID:</div>
                                <div className="value">{item.job_id}</div>
                            </div>
                            <div className="description-item">
                                <div className="label">Job Description:</div>
                                <div className="value">{item.job_description}</div>
                            </div>
                            <div className="description-item">
                                <div className="label">Customer ID:</div>
                                <div className="value">{item.customer_reference_id}</div>
                            </div>
                            <div className="description-item">
                                <div className="label">Beginning Balance:</div>
                                <div className="value">{numeral(item.beginning_balance).format('$0,0.00')}</div>
                            </div>
                            <div className="description-item">
                                <div className="label">Amount:</div>
                                <div className="value">{numeral(item.amount).format('$0,0.00')}</div>
                            </div>
                            <div className="description-item">
                                <div className="label">Ending Balance:</div>
                                <div className="value">{numeral(item.ending_balance).format('$0,0.00')}</div>
                            </div>
                            <div className="description-item">
                                <div className="label">Pieces:</div>
                                <div className="value">{numeral(item.pieces).format('0,0')}</div>
                            </div>
                            <div className="description-item">
                                <div className="label">Containers:</div>
                                <div className="value">{numeral(item.containers).format('0,0')}</div>
                            </div>
                            {mobileActions}
                        </div>
                    </td>
                    <td className="crid-column desktop-column">{item.crid}</td>
                    <td className="transaction-type-column desktop-column">{item.transaction_type}</td>
                    <td className="transaction-number-column">{item.transaction_number}</td>
                    <td className="date-column desktop-column">{moment(item.date).format('MM/DD/YYYY')}</td>
                    <td className="job-id-column desktop-column">{item.job_id}</td>
                    <td className="job-description-column desktop-column">{item.job_description}</td>
                    <td className="customer-id-column desktop-column">{item.customer_reference_id}</td>
                    <td className="beginnig-balance-column text-right">{numeral(item.beginning_balance).format('$0,0.00')}</td>
                    <td className="amount-column desktop-column text-right">{numeral(item.amount).format('$0,0.00')}</td>
                    <td className="ending-balance-column text-right">{numeral(item.ending_balance).format('$0,0.00')}</td>
                    <td className="pieces-column text-right">{numeral(item.pieces).format('0,0')}</td>
                    <td className="containers-column text-right">{numeral(item.containers).format('0,0')}</td>
                    {actions}
                </tr>
            );
        });

        if (!empty(props.filters.date)) {
            let actionsTotal = null;
            if (parseInt(props.user.type) === 1 || parseInt(props.user.type) === 2) {   // Super Admin, Admin
                actionsTotal = <td className="actions-column desktop-column"></td>;
            }

            rows.push(
                <tr>
                    <td className="permit-column"></td>
                    <td className="crid-column desktop-column"></td>
                    <td className="transaction-type-column desktop-column"></td>
                    <td className="transaction-number-column"></td>
                    <td className="date-column desktop-column"></td>
                    <td className="job-id-column desktop-column"></td>
                    <td className="job-description-column desktop-column"></td>
                    <td className="customer-id-column desktop-column"></td>
                    <td className="beginnig-balance-column text-right"></td>
                    <td className="amount-column desktop-column text-right"><b>{numeral(props.total).format('$0,0.00')}</b></td>
                    <td className="ending-balance-column text-right"></td>
                    <td className="pieces-column text-right"></td>
                    <td className="containers-column text-right"></td>
                    {actionsTotal}
                </tr>
            );
        }

        return rows;
    };

    const renderDailyEpsElements = () => {
        if (parseInt(props.dataType) !== 2) {
            return;
        }

        if (props.fetchingElements) {
            return (
                <tr>
                    <td colSpan="14"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (props.items.length === 0) {
            return (
                <tr>
                    <td colSpan="14">No results</td>
                </tr>
            );
        }

        let rows = props.items.map((item) => {
            let editIcon = (
                <div className="action-icon">
                    <Tooltip title="Edit" position="bottom" arrow size="small" className="action-tooltip">
                        <Link to={'/transactions/edit/2/' + item.id}><i className="fa-solid fa-pen-to-square"></i></Link>
                    </Tooltip>
                </div>
            );
            let deleteIcon = (
                <div className="action-icon">
                    <Tooltip title="Delete" position="bottom" arrow size="small" className="action-tooltip">
                        <i className="fa-solid fa-trash" onClick={handleDeleteModal.bind(this, item.id)}></i>
                    </Tooltip>
                </div>
            );

            let mobileActions = null;
            let actions = null;
            if (parseInt(props.user.type) === 1 || parseInt(props.user.type) === 2) {   // Super Admin, Admin
                mobileActions = (
                    <div className="description-actions">
                        {editIcon}
                        {deleteIcon}
                    </div>
                );
                actions = (
                    <td className="actions-column desktop-column">
                        <div className="actions-container">
                            {editIcon}
                            {deleteIcon}
                        </div>
                    </td>
                );
            }

            return (
                <tr>
                    <td className="daily-permit-column">
                        <div className="name">{item.permit_number}</div>
                        <div className="description">
                            <div className="description-item">
                                <div className="label">Permit #:</div>
                                <div className="value">{item.permit_number}</div>
                            </div>
                            <div className="description-item">
                                <div className="label">Date:</div>
                                <div className="value">{moment(item.date).format('MM/DD/YYYY')}</div>
                            </div>
                            <div className="description-item">
                                <div className="label">Transaction ID:</div>
                                <div className="value">{item.transaction_id}</div>
                            </div>
                            <div className="description-item">
                                <div className="label">Transaction Type:</div>
                                <div className="value">{item.transaction_type}</div>
                            </div>
                            <div className="description-item">
                                <div className="label">Description:</div>
                                <div className="value">{item.description}</div>
                            </div>
                            <div className="description-item">
                                <div className="label">Detail:</div>
                                <div className="value">{item.detail}</div>
                            </div>
                            <div className="description-item">
                                <div className="label">Payment Method:</div>
                                <div className="value">{item.payment_method}</div>
                            </div>
                            <div className="description-item">
                                <div className="label">Amount:</div>
                                <div className="value">{numeral(item.amount).format('$0,0.00')}</div>
                            </div>
                            {mobileActions}
                        </div>
                    </td>
                    <td className="daily-date-column desktop-column">{moment(item.date).format('MM/DD/YYYY')}</td>
                    <td className="daily-transaction-id-column desktop-column">{item.transaction_id}</td>
                    <td className="daily-transaction-type-column desktop-column">{item.transaction_type}</td>
                    <td className="daily-description-column desktop-column">{item.description}</td>
                    <td className="daily-detail-column desktop-column">{item.detail}</td>
                    <td className="daily-payment-method-column desktop-column">{item.payment_method}</td>
                    <td className="daily-amount-colum desktop-column text-right">{numeral(item.amount).format('$0,0.00')}</td>
                    {actions}
                </tr>
            );
        });

        if (!empty(props.filters.date)) {
            let actionsTotal = null;
            if (parseInt(props.user.type) === 1 || parseInt(props.user.type) === 2) {   // Super Admin, Admin
                actionsTotal = <td className="actions-column desktop-column"></td>;
            }

            rows.push(
                <tr>
                    <td className="daily-permit-column"></td>
                    <td className="daily-date-column"></td>
                    <td className="daily-transaction-type-column"></td>
                    <td className="daily-description-column"></td>
                    <td className="daily-detail-column"></td>
                    <td className="daily-payment-method-column"></td>
                    <td className="daily-amount-colum text-right"><b>{numeral(props.total).format('$0,0.00')}</b></td>
                    {actionsTotal}
                </tr>
            );
        }

        return rows;
    };

    const handleSort = (column, type) => {
        props.setSort(column, type, props.type);
    };

    const renderHeader = (label, field) => {
        let sortType = 'asc';
        let sortIcon = <i className="fas fa-sort" />;
        if (props.sort.column === field) {
            if (props.sort.type === 'desc') {
                sortIcon = <i className="fas fa-sort-down" />;
                sortType = 'asc';
            } else {
                sortIcon = <i className="fas fa-sort-up" />;
                sortType = 'desc';
            }
        }

        return (
            <Fragment>
                <div className="sort-item" onClick={handleSort.bind(this, field, sortType)}>{label} {sortIcon}</div>
                {/* <div className="column-filter">
                    <input type="text" />
                </div> */}
            </Fragment>
        );
    };

    const handleSetFilter = (name, event) => {
        props.setFilter(name, event.target.value);

        if (name === 'date') {
            props.setActivePage(1);
            props.fetchElements();
        }
    };

    const handleSetFilterSelect = (name, event) => {
        props.setFilter(name, event.target.value);
        props.setActivePage(1);
        props.fetchElements();
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            props.setActivePage(1);
            props.fetchElements();
        }
    };

    const handleBlur = (name) => {
        if (props.filters[name] !== props.oldFilters[name]) {
            props.setActivePage(1);
            props.fetchElements();
        }
    };

    let actionsHeader = null;
    if (parseInt(props.user.type) === 1 || parseInt(props.user.type) === 2) {   // Super Admin, Admin
        actionsHeader = <th className="actions-column desktop-column"></th>;
    }

    const renderAllTransactionsHeader = () => {
        if (parseInt(props.dataType) !== 1) {
            return;
        }

        return (
            <thead>
                <tr className="header-row">
                    <th className="permit-column">
                        <span className="desktop-column">{renderHeader('Permit / Pub', 'permit_pub')}</span>
                    </th>
                    <th className="crid-column desktop-column">{renderHeader('CRID', 'crid')}</th>
                    <th className="transaction-type-column desktop-column">{renderHeader('Transaction Type', 'transaction_type')}</th>
                    <th className="transaction-number-column">{renderHeader('Transaction #', 'transaction_number')}</th>
                    <th className="date-column desktop-column">{renderHeader('Date', 'date')}</th>
                    <th className="job-id-column desktop-column">{renderHeader('Job ID', 'job-id')}</th>
                    <th className="job-description-column desktop-column">{renderHeader('Job Description', 'job_description')}</th>
                    <th className="customer-id-column desktop-column">{renderHeader('Customer ID', 'customer_reference_id')}</th>
                    <th className="beginnig-balance-column text-right">{renderHeader('Beginning Balance', 'beginning_balance')}</th>
                    <th className="amount-colum desktop-column text-right">{renderHeader('Amount', 'amount')}</th>
                    <th className="ending-balance-column text-right">{renderHeader('Ending Balance', 'ending_balance')}</th>
                    <th className="pieces-column text-right">{renderHeader('Pieces', 'pieces')}</th>
                    <th className="containers-column text-right">{renderHeader('Containers', 'containers')}</th>
                    {actionsHeader}
                </tr>
                <tr>
                    <th className="column-filter desktop-column">
                        <select value={props.filters.permit_pub} onChange={handleSetFilterSelect.bind(this, 'permit_pub')}>
                            <option value=""></option>
                            {props.filtersOptions.permits.map((option) => <option value={option}>{option}</option>)}
                        </select>
                    </th>
                    <th className="column-filter desktop-column">
                        <input
                            type="text"
                            value={props.filters.crid}
                            onKeyPress={handleKeyPress}
                            onBlur={handleBlur.bind(this, 'crid')}
                            onChange={handleSetFilter.bind(this, 'crid')}
                        />
                    </th>
                    <th className="column-filter desktop-column">
                        <select value={props.filters.transaction_type} onChange={handleSetFilterSelect.bind(this, 'transaction_type')}>
                            <option value=""></option>
                            {props.filtersOptions.transactionTypes.map((option) => <option value={option}>{option}</option>)}
                        </select>
                    </th>
                    <th className="column-filter desktop-column">
                        <input
                            type="text"
                            value={props.filters.transaction_number}
                            onKeyPress={handleKeyPress}
                            onBlur={handleBlur.bind(this, 'transaction_number')}
                            onChange={handleSetFilter.bind(this, 'transaction_number')}
                        />
                    </th>
                    <th className="column-filter desktop-column">
                        <input
                            type="date"
                            value={props.filters.date}
                            onKeyPress={handleKeyPress}
                            onBlur={handleBlur.bind(this, 'date')}
                            onChange={handleSetFilter.bind(this, 'date')}
                        />
                    </th>
                    <th className="column-filter desktop-column">
                        <input
                            type="text"
                            value={props.filters.job_id}
                            onKeyPress={handleKeyPress}
                            onBlur={handleBlur.bind(this, 'job_id')}
                            onChange={handleSetFilter.bind(this, 'job_id')}
                        />
                    </th>
                    <th className="column-filter desktop-column">
                        <input
                            type="text"
                            value={props.filters.job_description}
                            onKeyPress={handleKeyPress}
                            onBlur={handleBlur.bind(this, 'job_description')}
                            onChange={handleSetFilter.bind(this, 'job_description')}
                        />
                    </th>
                    <th className="column-filter desktop-column">
                        <input
                            type="text"
                            value={props.filters.customer_reference_id}
                            onKeyPress={handleKeyPress}
                            onBlur={handleBlur.bind(this, 'customer_reference_id')}
                            onChange={handleSetFilter.bind(this, 'customer_reference_id')}
                        />
                    </th>
                    <th className="column-filter beginnig-balance-column">
                        <input
                            type="text"
                            value={props.filters.beginning_balance}
                            onKeyPress={handleKeyPress}
                            onBlur={handleBlur.bind(this, 'beginning_balance')}
                            onChange={handleSetFilter.bind(this, 'beginning_balance')}
                        />
                    </th>
                    <th className="column-filter desktop-column">
                        <input
                            type="text"
                            value={props.filters.amount}
                            onKeyPress={handleKeyPress}
                            onBlur={handleBlur.bind(this, 'amount')}
                            onChange={handleSetFilter.bind(this, 'amount')}
                        />
                    </th>
                    <th className="column-filter ending-balance-column">
                        <input
                            type="text"
                            value={props.filters.ending_balance}
                            onKeyPress={handleKeyPress}
                            onBlur={handleBlur.bind(this, 'ending_balance')}
                            onChange={handleSetFilter.bind(this, 'ending_balance')}
                        />
                    </th>
                    <th className="column-filter pieces-column">
                        <input
                            type="text"
                            value={props.filters.pieces}
                            onKeyPress={handleKeyPress}
                            onBlur={handleBlur.bind(this, 'pieces')}
                            onChange={handleSetFilter.bind(this, 'pieces')}
                        />
                    </th>
                    <th className="column-filter containers-column">
                        <input
                            type="text"
                            value={props.filters.containers}
                            onKeyPress={handleKeyPress}
                            onBlur={handleBlur.bind(this, 'containers')}
                            onChange={handleSetFilter.bind(this, 'containers')}
                        />
                    </th>
                </tr>
            </thead>
        );
    };

    const renderDailyEpsHeader = () => {
        if (parseInt(props.dataType) !== 2) {
            return;
        }

        return (
            <thead>
                <tr className="header-row">
                    <th className="daily-permit-column">
                        <span className="desktop-column">{renderHeader('Permit #', 'permit_number')}</span>
                    </th>
                    <th className="daily-date-column desktop-column">{renderHeader('Date', 'date')}</th>
                    <th className="daily-transaction-id-column desktop-column">{renderHeader('Transaction ID', 'transaction_id')}</th>
                    <th className="daily-transaction-type-column desktop-column">{renderHeader('Transaction Type', 'transaction_type')}</th>
                    <th className="daily-description-column desktop-column">{renderHeader('Description', 'description')}</th>
                    <th className="daily-detail-column desktop-column">{renderHeader('Detail', 'detail')}</th>
                    <th className="daily-payment-method-column desktop-column">{renderHeader('Payment Method', 'payment_method')}</th>
                    <th className="daily-amount-colum desktop-column text-right">{renderHeader('Amount', 'amount')}</th>
                    {actionsHeader}
                </tr>
                <tr>
                    <th className="column-filter desktop-column">
                        <select value={props.filters.permit_number} onChange={handleSetFilterSelect.bind(this, 'permit_number')}>
                            <option value=""></option>
                            <option value="1">1</option>
                            <option value="600">600</option>
                        </select>
                    </th>
                    <th className="column-filter desktop-column">
                        <input
                            type="date"
                            value={props.filters.date}
                            onKeyPress={handleKeyPress}
                            onBlur={handleBlur.bind(this, 'date')}
                            onChange={handleSetFilter.bind(this, 'date')}
                        />
                    </th>
                    <th className="column-filter desktop-column">
                        <select value={props.filters.transaction_type} onChange={handleSetFilterSelect.bind(this, 'transaction_type')}>
                            <option value=""></option>
                            {props.filtersOptions.transactionTypes.map((option) => <option value={option}>{option}</option>)}
                        </select>
                    </th>
                    <th className="column-filter desktop-column">
                        <input
                            type="text"
                            value={props.filters.description}
                            onKeyPress={handleKeyPress}
                            onBlur={handleBlur.bind(this, 'description')}
                            onChange={handleSetFilter.bind(this, 'description')}
                        />
                    </th>
                    <th className="column-filter desktop-column">
                        <input
                            type="text"
                            value={props.filters.detail}
                            onKeyPress={handleKeyPress}
                            onBlur={handleBlur.bind(this, 'detail')}
                            onChange={handleSetFilter.bind(this, 'detail')}
                        />
                    </th>
                    <th className="column-filter desktop-column">
                        <input
                            type="text"
                            value={props.filters.payment_method}
                            onKeyPress={handleKeyPress}
                            onBlur={handleBlur.bind(this, 'payment_method')}
                            onChange={handleSetFilter.bind(this, 'payment_method')}
                        />
                    </th>
                    <th className="column-filter desktop-column">
                        <input
                            type="text"
                            value={props.filters.amount}
                            onKeyPress={handleKeyPress}
                            onBlur={handleBlur.bind(this, 'amount')}
                            onChange={handleSetFilter.bind(this, 'amount')}
                        />
                    </th>
                </tr>
            </thead>
        );
    };

    return (
        <div className="table-container">
            <table>
                {renderAllTransactionsHeader()}
                {renderDailyEpsHeader()}
                <tbody>
                    {renderAllTransactionsElements()}
                    {renderDailyEpsElements()}
                </tbody>
            </table>
            <Pagination
                pagination={props.pagination}
                setActivePage={props.setActivePage}
            />
        </div>
    );
}

export default Table;
